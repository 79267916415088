<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-form-group
                label="Tárgy"
            >
              <validation-provider
                  #default="{ errors }"
                  name="tárgy"
                  :rules="subjectRequired ? 'required' : ''"
                  vid="title"
              >
                <b-form-input
                    v-model="formData.title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="tárgy"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-group
                label="Címzett"
            >
              <validation-provider
                  #default="{ errors }"
                  name="címzett"
                  rules="required"
                  vid="userId"
              >
                <v-select
                    v-model="formData.userId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="userOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-group
                label="Üzenet típusa"
            >
              <validation-provider
                  #default="{ errors }"
                  name="üzenet típus"
                  rules=""
                  vid="modelType"
              >
                <v-select
                    v-model="formData.modelType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="modelTypeOptions"
                    :selectable="(option) => !option.disabled"
                    @input="onChangeEntityType"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-group
                label="Példány"
            >
              <validation-provider
                  #default="{ errors }"
                  name="példány"
                  :rules="modelIdRequired ? 'required' : ''"
                  vid="modelId"
              >
                <v-select
                    v-model="formData.modelId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="modelOptionsForModelType"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
                label="Üzenet"
            >
              <validation-provider
                  #default="{ errors }"
                  name="üzenet"
                  rules="required"
                  vid="message"
              >
                <editor
                    v-model="formData.message"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import editor from '@/views/pages/_components/editor.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    editor,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'user',
      formData: {
        userId: '',
        modelType: '',
        modelId: '',
        title: '',
        message: '',
      },
      userOptions: [],
      modelTypeOptions: [],
      modelOptionsForModelType: [],
      modelIdRequired: false,
      subjectRequired: true,
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchUsersForSelect').then(response => {
      this.userOptions = response
    })

    this.$store.dispatch('fetchMessageEntityTypes').then(response => {
      this.modelTypeOptions = response.data.values
    })
  },
  methods: {
    onChangeEntityType() {
      this.modelIdRequired = false
      this.subjectRequired = true
      this.modelOptionsForModelType = []
      if (this.formData.modelType && this.formData.modelType.value) {
          this.$store.dispatch('fetchEntitiesByEntityType', {entityType: this.formData.modelType.value}).then(response => {
          this.modelOptionsForModelType = response.data.values
          this.modelIdRequired = true
          this.subjectRequired = false
        })
      } else {
        this.formData.modelId = ''
      }
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = {
            userId: this.formData.userId.value,
            title: this.formData.title,
            message: this.formData.message,
          }

          if (this.formData.modelType && this.formData.modelType.value) {
            fd.modelType = this.formData.modelType.value
            fd.modelId = this.formData.modelId.value
          }

          this.$store.dispatch('storeMessage', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({ name: 'messages' })
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
